<template>
	<div class="content">
		<div class="main nav">
			<img
				class="logo"
				src="../../assets/image/pc/pcHeader/logo.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	created() {},
	mounted() {},
	methods: {
		goHome() {
			this.navIndex = 1
			sessionStorage.navIndex = 1
			this.$router.push({ name: 'home' })
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: #ffffff;
	border-bottom: solid 1px #eeeeee;
}
.nav {
	display: flex;
	align-items: center;
	height: 80px;
	justify-content: space-between;
	.logo {
		width: 166px;
		height: 39px;
	}
	.logo:hover {
		cursor: pointer;
	}
}
</style>
