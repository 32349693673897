<template>
  <div class="footer">
    <div class="top_content">
      <img
        class="logo_img"
        src="../../assets/image/pc/pcFooter/logo.png"
        alt=""
      />
      <div class="contact">
        <div class="title">联系我们</div>
        <div class="content">
          公司地址：湖南省长沙市岳麓区环湖路1177号金茂广场南塔 湘江集团大厦19楼
        </div>
        <div class="content">联系方式：400-862-8806</div>
      </div>
      <div class="cooperation">
        <div class="title">更多合作</div>
        <div class="content click code">
          商务合作
          <img
            class="code_img"
            src="../../assets/image/pc/pcFooter/code.png"
            alt=""
          />
        </div>
        <div
          class="content click"
          @click="toPage('https://mp.weixin.qq.com/s/YgpJofxRwCwEAD5L7W_XcA')"
        >
          加入我们
        </div>
      </div>
    </div>
    <div class="bottom_content">
      <!-- <div class="img_list flexVerticalCenter"> -->
      <div class="img_list">
        <!-- <img
					class="img"
					v-for="(item, i) in imgList"
					:key="i"
					:src="item.img"
					alt=""
					@click="toPage(item.url)"
				/> -->
      </div>
      <div class="des">
        <div class="content">
          Copyright © 2017-2023 聚趣教育科技有限公司版权所有
        </div>
        <div class="content click" @click="toPage('http://beian.miit.gov.cn')">
          备案号：湘ICP备2021002148号-1
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          img: require('@/assets/image/pc/pcFooter/bilibili.png'),
          url: 'https://space.bilibili.com/517809714',
        },
        {
          img: require('@/assets/image/pc/pcFooter/taobao.png'),
          url: 'https://juquedu.taobao.com/',
        },
        // {
        // 	img: require('@/assets/image/pc/pcFooter/weidian.png'),
        // 	url: '',
        // },
      ],
    }
  },
  methods: {
    toPage(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  height: 358px;
  background: #222326;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #afa6a6;
  font-size: 14px;
  min-width: 1200px;
  color: #ffffff;
  .top_content {
    min-width: 1200px;
    display: flex;
    padding-bottom: 54px;
    border-bottom: 1px #3f4148 solid;
    .contact {
      margin-left: 84px;
    }
    .cooperation {
      margin-left: 124px;
    }
    .logo_img {
      width: 246px;
      height: 49px;
      margin: 46px 0 0 34px;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #b7b8c2;
    a {
      color: #b7b8c2;
    }
  }
  .click {
    cursor: pointer;
  }
  .click:hover {
    color: #ffffff;
  }
  .code {
    position: relative;
    .code_img {
      width: 121px;
      height: 121px;
      display: none;
      position: absolute;
      top: 0;
      left: 86px;
    }
  }
  .code:hover {
    .code_img {
      display: block;
    }
  }
  .bottom_content {
    min-width: 1200px;
    display: flex;
    margin-top: 32px;
    .img_list {
      width: 246px;
      margin: 0 0 0 34px;
      margin-right: 84px;
      display: flex;
      .img {
        width: 50px;
        height: 50px;
        margin-right: 38px;
        cursor: pointer;
      }
      .img:first-child {
        // margin-left: 34px;
        // margin-right: 83px;
      }
      // .img:nth-child(3) {
      // 	width: 70px;
      // 	margin-right: 83px;
      // }
    }
  }
}
</style>
